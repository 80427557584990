/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import i18nData from "./i18n_data"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      ...i18nData,
    },
    fallbackLng: "fr",
    supportedLngs: ["en", "fr"],
    debug: true,
    initImmediate: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ["querystring", "navigator"],
      caches: [],
    },
  })
