import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from "react"

import { createClient, type SupabaseClient } from "@supabase/supabase-js"
import { type Database } from "../types/database"

// Create a single supabase client for interacting with your database
export const supabase = createClient<Database>('https://ywcqbkxcbbtvmzdhxhwn.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl3Y3Fia3hjYmJ0dm16ZGh4aHduIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODc2MTAzMDQsImV4cCI6MjAwMzE4NjMwNH0.T-qWuBIbWW852b6bbR_TqWrlwKUDGcI4k66ryyPsMxQ')

type Supabase = SupabaseClient<Database>
export const SupabaseContext = createContext<Supabase>(
  undefined as unknown as Supabase
)

export const SupabaseProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  )
}

const useSupabase = (): Supabase => {
  const supabase = useContext(SupabaseContext)

  if (Object.keys(supabase).length === 0) {
    throw new Error("Must wrap with SupabaseProvider")
  }

  return supabase
}

export default useSupabase
