/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ImageList,
  ImageListItem,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material"
import React, { type FC, useCallback, useState } from "react"
import "react-quill/dist/quill.snow.css"
import SendIcon from "@mui/icons-material/Send"
import ImageUploading, { type ImageListType } from "react-images-uploading"
import LoadingButton from "@mui/lab/LoadingButton"
import useSupabase from "../util/supabase-provider"
import { filter, isNil, isNot, isString } from "remeda"
import ReactQuill from "react-quill"
import { useTranslation } from "react-i18next"
import ShortUniqueId from "short-unique-id"
import { type Note } from "../Home"
import { type ImageType } from "react-images-uploading/dist/typings"
import { __DEV__ } from "../util/constants"
import NewNoteStub from "./NewNoteStub"

const isImageType = (i: ImageType | string): i is ImageType =>
  (i as ImageType).data_url != null

const modules = {
  toolbar: [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      "link",
      "clean",
    ] as const,
  ],
}

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "indent",
  "list",
  "link",
]

const NewNote: FC<{ note?: Note; onDone?: () => void }> = ({
  note,
  onDone,
}) => {
  const supabase = useSupabase()

  const [noteId, setNoteId] = useState<number | undefined>(note?.id)
  const [code, setCode] = useState<string>()
  const [title, setTitle] = useState(note?.title ?? "")
  const [text, setText] = useState(note?.text ?? "")
  const [author, setAuthor] = useState(note?.author_name ?? "")
  const [images, _setImages] = useState<Array<ImageType | string>>(
    note?.photos ?? []
  )
  const [error, setError] = useState<string>()

  const [uploading, setUploading] = useState(false)
  const { t } = useTranslation("newNote")
  const [open, setOpen] = useState(note != null)

  const setImages = useCallback((images: ImageListType) => {
    console.log("images", images)
    _setImages(images)
  }, [])

  const onErrorClose = useCallback(() => {
    console.log("onErrorClose")
    setError(undefined)
  }, [setError])

  const onCodeClose = useCallback(() => {
    console.log("onCodeClose")
    setCode(undefined)
  }, [setCode])

  const send = useCallback(async () => {
    try {
      setUploading(true)
      const payload = {
        text,
        title,
        author_name: author,
      }

      const draft =
        noteId == null
          ? (
              await supabase
                .from("note")
                .insert({
                  code: new ShortUniqueId({
                    length: 6,
                    dictionary: "alphanum_upper",
                  })(),
                  test: __DEV__,
                  ...payload,
                })
                .select()
            ).data?.[0]
          : (
              await supabase
                .from("note")
                .update(payload)
                .eq("id", noteId)
                .select()
            ).data?.[0]

      if (draft == null) {
        setError(t("noteCreation"))
        throw Error("Note creation failed")
      }

      setNoteId(draft.id)

      const photoUrls = await Promise.all(
        images.map(async (image) => {
          if (isString(image)) {
            console.log("Already uploaded", image)
            return image
          }

          if (image.file == null) {
            return undefined
          }

          const url = `https://seaweed.paour.com/${draft.id}/${
            image.file?.name ?? ""
          }`
          const body = new FormData()
          body.append("file", image.file)
          const uploaded = await fetch(url, { method: "POST", body })

          return uploaded?.status >= 200 && uploaded?.status < 400
            ? url
            : undefined
        })
      )

      await supabase
        .from("note")
        .update({
          photos: filter(photoUrls, isNot(isNil)),
        })
        .eq("id", draft.id)

      setCode(draft.code ?? "")

      onDone?.()
    } finally {
      setUploading(false)
    }
  }, [text, title, author, noteId, supabase, images, onDone, t])

  if (!open) {
    return (
      <NewNoteStub
        onPress={() => {
          setOpen(true)
        }}
      />
    )
  }

  return (
    <ImageUploading
      multiple
      value={images as ImageType[]}
      onChange={setImages}
      maxNumber={10}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        isDragging,
        dragProps,
      }) => (
        <Card>
          <CardHeader title={t(note == null ? "header" : "edit.header")} />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                label={t("title")}
                placeholder={t("titlePlaceholder")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTitle(event.target.value)
                }}
                value={title}
              />
              <TextField
                required
                label={t("author")}
                placeholder={t("authorPlaceholder")}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAuthor(event.target.value)
                }}
                value={author}
              />
              <ReactQuill
                onChange={setText}
                value={text}
                formats={formats}
                modules={modules}
              />
              <ImageList cols={3} rowHeight={164}>
                {imageList.map((image: ImageType | string) => (
                  <ImageListItem
                    key={isImageType(image) ? image.data_url : image}
                    sx={{ width: 164, height: 164 }}
                  >
                    <img
                      src={isImageType(image) ? image.data_url : image}
                      alt=""
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              {images.length > 0 ? (
                <Button size="small" onClick={onImageRemoveAll}>
                  {t("deleteImages")}
                </Button>
              ) : undefined}
            </Stack>
          </CardContent>
          <CardActions sx={{ paddingX: 2 }}>
            <Stack direction="row" justifyContent="space-between" flex={1}>
              <Button
                size="small"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                {t("addImages")}
              </Button>
              <LoadingButton
                endIcon={<SendIcon />}
                variant="outlined"
                onClick={send}
                loading={uploading}
                loadingPosition="end"
                color="secondary"
                disabled={author.length === 0 || text.length === 0}
              >
                {t(note == null ? "send" : "edit.send")}
              </LoadingButton>
            </Stack>
          </CardActions>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={code != null}
            onClose={onCodeClose}
          >
            <Alert severity="success" onClose={onCodeClose}>
              {t("code", { code })}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={error != null}
            onClose={onErrorClose}
          >
            <Alert severity="error" onClose={onErrorClose}>
              {error}
            </Alert>
          </Snackbar>
        </Card>
      )}
    </ImageUploading>
  )
}

export default NewNote
