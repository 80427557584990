import "./App.css"
import React, { type FC, useEffect } from "react"
import { SupabaseProvider } from "./util/supabase-provider"
import Home from "./Home"

import "./util/i18n_load"
import { useTranslation } from "react-i18next"
import dayjs from "./util/dayjs"
import { createTheme, ThemeProvider } from "@mui/material"

const theme = createTheme({
  palette: {
    primary: { main: "#F06292" },
    secondary: { main: "#42A5F5" },
  },
})

const App: FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    dayjs.locale(t("lang"))
  }, [t])

  return (
    <SupabaseProvider>
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
    </SupabaseProvider>
  )
}

export default App
