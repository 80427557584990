export default {
  fr: {
    translation: {
      lang: "fr",
      ok: "OK",
      cancel: "Cancel",
    },
    newNote: {
      header: "Nouvelle note",
      subheader: "Utilisez l'icône crayon pour créer votre note…",
      title: "Titre",
      titlePlaceholder: "Chère Gwenaëlle…",
      author: "Auteur",
      authorPlaceholder: "Mon nom et/ou adresse email",
      send: "Envoyer",
      addImages: "Ajouter des photos",
      deleteImages: "Supprimer toutes",
      code: "Votre note a été envoyée, pour pouvoir l'éditer, notez le code \"{{code}}\"",
      noteCreation: "La création de la note a échoué",
      edit: {
        header: "Editer la note",
        send: "Mettre à jour",
      },
    },
    noteCard: {
      dialog: {
        title: "Code",
        content: "Veuillez saisir le code qui vous a été fourni à la création de la note.",
        error: "Code erroné, veuillez recommencer.",
      },
    },
    head: {
      title: "Gwenaëlle dans nos ❤️",
      lines: [
        "Partagez en quelques mots vos souvenirs ou anecdotes avec la famille et les amis.",
      ],
    },
  },
  en: {
    translation: {
      lang: "en",
      ok: "OK",
      cancel: "Cancel",
    },
    newNote: {
      header: "New note",
      subheader: "Use the pencil icon to create your note…",
      title: "Title",
      titlePlaceholder: "Dear Gwen…",
      author: "Author",
      authorPlaceholder: "My name and/or email address",
      send: "Send",
      addImages: "Add pictures",
      deleteImages: "Remove all",
      code: "Your note has been sent, to edit it later, remember this code \"{{code}}\"",
      noteCreation: "The note couldn't be created",
      edit: {
        header: "Edit note",
        send: "Update",
      },
    },
    noteCard: {
      dialog: {
        title: "Code",
        content: "Enter the code that was given to you when you first posted the note.",
        error: "Incorrect code, please try again.",
      },
    },
    head: {
      title: "Gwen in our ❤️",
      lines: [
        "Share in a few words your memories or anecdotes with family and friends.",
      ],
    },
  },
}
