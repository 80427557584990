import React, { type FC, useCallback, useEffect, useState } from "react"
import { type Note } from "../Home"
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import parse from "html-react-parser"
import { Image } from "mui-image"
import dayjs from "../util/dayjs"
import CreateIcon from "@mui/icons-material/Create"
import NewNote from "./NewNote"
import { useTranslation } from "react-i18next"

const NoteCard: FC<{ note: Note }> = ({ note }) => {
  const { t } = useTranslation("noteCard")
  const createdAt = dayjs(note.created_at)
  const photos = note.photos ?? []
  const [imageOpen, setImageOpen] = React.useState<string | undefined>(
    undefined
  )
  const [editOpen, setEditOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [securityCode, setSecurityCode] = useState("")
  const [securityError, setSecurityError] = useState(false)

  useEffect(() => {
    setSecurityError(false)
  }, [securityCode])
  useEffect(() => {
    setDialogOpen(false)
  }, [editOpen])
  const onCheckCode = useCallback(() => {
    if (note.code === securityCode) {
      setEditOpen(true)
    } else {
      setSecurityError(true)
    }
  }, [note.code, securityCode])

  if (editOpen) {
    return (
      <NewNote
        note={note}
        onDone={() => {
          setEditOpen(false)
        }}
      />
    )
  }

  return (
    <Card>
      <CardHeader
        title={note.title}
        subheader={`${note.author_name ?? "anonyme"} • ${
          createdAt.add(2, "day").isBefore(dayjs())
            ? createdAt.format("L")
            : createdAt.fromNow()
        }`}
        action={
          <IconButton
            onClick={() => {
              setDialogOpen(true)
            }}
            color="secondary"
            size="small"
          >
            <CreateIcon fontSize="small" color="disabled" />
          </IconButton>
        }
      />
      <CardContent>
        <Stack spacing={2} sx={{ paddingTop: 0 }}>
          {parse(note.text ?? "")}
          {photos.length > 0 ? (
            <>
              <Divider />
              <ImageList
                rowHeight={164}
                sx={{
                  gridTemplateColumns:
                    "repeat(auto-fill, minmax(180px, 1fr))!important",
                }}
              >
                {photos.map((p) => (
                  <ImageListItem key={p} sx={{ width: 164, height: 164 }}>
                    <img
                      src={`${p}?height=164&width=164`}
                      alt=""
                      onClick={() => {
                        setImageOpen(p)
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </>
          ) : undefined}
        </Stack>
      </CardContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={imageOpen != null}
        onClick={() => {
          setImageOpen(undefined)
        }}
      >
        <Image src={imageOpen ?? ""} alt="" duration={200} fit={"contain"} />
      </Backdrop>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
      >
        <DialogTitle>{t("dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ paddingBottom: 2 }}>
            <div>{t("dialog.content")}</div>
            {securityError ? (
              <Typography sx={{ color: "error.main" }}>
                {t("dialog.error")}
              </Typography>
            ) : undefined}
          </DialogContentText>
          <TextField
            autoFocus
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSecurityCode(event.target.value)
            }}
            value={securityCode}
            label={t("dialog.title")}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false)
            }}
          >
            {t("translation:cancel")}
          </Button>
          <Button onClick={onCheckCode}> {t("translation:ok")}</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default NoteCard
