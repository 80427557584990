import { Card, CardHeader, IconButton } from "@mui/material"
import React, { type FC } from "react"
import CreateIcon from "@mui/icons-material/Create"
import { useTranslation } from "react-i18next"

const NewNoteStub: FC<{ onPress: () => void }> = ({ onPress }) => {
  const { t } = useTranslation("newNote")

  return (
    <Card>
      <CardHeader
        title={t("header")}
        subheader={t("subheader")}
        action={
          <IconButton onClick={onPress} color="primary">
            <CreateIcon />
          </IconButton>
        }
      />
    </Card>
  )
}

export default NewNoteStub
