import React, { type FC } from "react"
import { Card, CardHeader, CardMedia, Stack } from "@mui/material";
import { useTranslation } from "react-i18next"

const Head: FC = () => {
  const { t } = useTranslation("head")

  const lines: string[] = t("head:lines", { returnObjects: true })

  return (
    <Card>
      <CardMedia component="img" height={200} image="/gwen.jpg" />
      <CardHeader title={t("title")} />
      <Stack spacing={2} sx={{ paddingX: 2, paddingBottom: 2 }}>
      {lines.map((l, i) => (
        <div key={`line-${i}`}>{l}</div>
      ))}
      </Stack>
    </Card>
  )
}

export default Head
