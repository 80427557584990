import React, { type FC, useEffect, useState } from "react"
import { Box, CircularProgress, Container, Stack } from "@mui/material"
import useSupabase from "./util/supabase-provider"
import { type Database } from "./types/database"
import NoteCard from "./components/NoteCard"
import NewNote from "./components/NewNote"
import Head from "./components/Head"
import { __DEV__ } from "./util/constants"
import { isEmpty, isNullish } from "remeda"

export type Note = Database["public"]["Tables"]["note"]["Row"]

const Home: FC = () => {
  const supabase = useSupabase()
  // @ts-expect-error injected state
  const [notes, setNotes] = useState<Note[]>(window.__PRELOADED_STATE__)
  const [reload, setReload] = useState(0)

  useEffect(() => {
    supabase
      .from("note")
      .select()
      .eq("test", __DEV__)
      .order("created_at", { ascending: false })
      .then((d) => {
        setNotes(oldNotes => isNullish(d?.data) || isEmpty(d?.data) ? oldNotes : d.data)
      }, console.error)
  }, [setNotes, supabase, reload])

  useEffect(() => {
    const channel = supabase
      .channel("any")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "note" },
        () => {
          setReload((i) => i + 1)
        }
      )
      .subscribe()

    return () => {
      channel.unsubscribe().finally(console.log)
    }
  }, [supabase])

  // @ts-expect-error injected state
  window.snapSaveState = () => ({
    __PRELOADED_STATE__: notes
  })

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Container maxWidth="md" sx={{ height: 1 }}>
        <Stack spacing={2} alignItems="stretch" sx={{ paddingY: 2 }}>
          <Head />
          <NewNote />
          {notes == null ? (
            <Stack direction="row" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : (
            notes.map((note) => <NoteCard key={note.id} note={note} />)
          )}
        </Stack>
      </Container>
    </Box>
  )
}
export default Home
